<div class="top-nav" *ngIf="!isErrorRoute">
    <mat-toolbar color="primary">
        <span>{{ 'app-name' | translate }}</span>
    </mat-toolbar>
</div>
<mat-drawer-container class="drawer-container">
    <mat-drawer mode="side" class="container-sidenav" opened>
        <ul>
            <li matRipple>
                <a routerLinkActive="active" [routerLink]="'/how-to'">{{ 'navigation.how-to' | translate }}</a>
            </li>
            <li matRipple>
                <a routerLinkActive="active" [routerLink]="'/programs'">{{ 'navigation.programs' | translate }}</a>
            </li>
            <li matRipple>
                <a routerLinkActive="active" [routerLink]="'/ingest-skill-graph'">{{ 'navigation.ingest-skill-graph' | translate }}</a>
            </li>
            <li matRipple>
                <a routerLinkActive="active" [routerLink]="'/check-pool-count'">{{ 'navigation.check-pool-count' | translate }}</a>
            </li>
        </ul>
    </mat-drawer>
    <mat-drawer-content class="container-body">
        <router-outlet>
        </router-outlet>
    </mat-drawer-content>
</mat-drawer-container>
