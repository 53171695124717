import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getSkillGraphs } from 'src/app/graphql/queries/getSkillGraphs.query';
import { ingestSkillGraph } from '../../graphql/mutations/ingestSkillGraph.mutation';
import { registerProgram } from '../../graphql/mutations/registerProgram.mutation';
import { updateProgram } from '../../graphql/mutations/updateProgram.mutation';
import { getProgramSkillGraphs } from '../../graphql/queries/getProgramSkillGraph.query';
import { getSyncedPrograms } from '../../graphql/queries/getSyncedPrograms.query';
import {
    getProgramSkillGraphs as GetProgramSkillGraphsResponse,
    getProgramSkillGraphsVariables as GetProgramSkillGraphsInput,
    getSkillGraphs as GetSkillGraphsResponse,
    getSyncedPrograms as GetSyncedProgramsResponse,
    ingestSkillGraph as IngestSkillGraphResponse,
    ingestSkillGraphVariables as IngestSkillGraphInputs,
    registerProgram as RegisterProgramResponse,
    registerProgramVariables as RegisterProgramInput,
    updateProgram as UpdateProgramResponse,
    updateProgramVariables as UpdateProgramInput,
    getSkillGraphCSVs as getSkillGraphCSVsResponse,
    getSkillGraphCSVsVariables as getSkillGraphCSVsInput,
    getItemPoolCountCSVs as getItemPoolCountCSVsResponse,
    getItemPoolCountCSVsVariables as getItemPoolCountCSVsInput
} from './../../../../schema/graphql-types';
import { getSkillGraphCSVs } from 'src/app/graphql/queries/getSkillGraphCSVs.query';
import { getItemPoolCountCSVs } from 'src/app/graphql/queries/getItemCountCSVs.query';

@Injectable({
    providedIn: 'root',
})
export class SavvyAdminDataService {
    constructor(private readonly apollo: Apollo) { }

    getSyncedPrograms(): Observable<GetSyncedProgramsResponse> {
        return this.apollo
            .query<GetSyncedProgramsResponse>({
                query: getSyncedPrograms,
            })
            .pipe(map(({ data }) => data));
    }

    getProgramSkillGraphs(input: GetProgramSkillGraphsInput): Observable<GetProgramSkillGraphsResponse> {
        return this.apollo
            .query<GetProgramSkillGraphsResponse>({
                query: getProgramSkillGraphs,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }

    getSkillGraphs(): Observable<GetSkillGraphsResponse> {
        return this.apollo
            .query<GetSkillGraphsResponse>({
                query: getSkillGraphs,
            })
            .pipe(map(({ data }) => data))
    }


    downloadSkillGraphCSVs(input: getSkillGraphCSVsInput): Observable<getSkillGraphCSVsResponse> {
        return this.apollo
            .query<getSkillGraphCSVsResponse>({
                query: getSkillGraphCSVs,
                variables: input,
            })
            .pipe(map(({ data }) => data))
    }

    registerProgram(input: RegisterProgramInput): Observable<RegisterProgramResponse | null | undefined> {
        return this.apollo
            .mutate<RegisterProgramResponse>({
                mutation: registerProgram,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }

    ingestSkillGraph(input: IngestSkillGraphInputs): Observable<IngestSkillGraphResponse | null | undefined> {
        return this.apollo
            .mutate<IngestSkillGraphResponse>({
                mutation: ingestSkillGraph,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }

    updateProgram(input: UpdateProgramInput): Observable<UpdateProgramResponse | null | undefined> {
        return this.apollo
            .mutate<UpdateProgramResponse>({
                mutation: updateProgram,
                variables: input,
            })
            .pipe(map(({ data }) => data));
    }

    downloadItemPoolCountCSVs(input: getItemPoolCountCSVsInput): Observable<getItemPoolCountCSVsResponse> {
        return this.apollo
            .query<getItemPoolCountCSVsResponse>({
                query: getItemPoolCountCSVs,
                variables: input,
            })
            .pipe(map(({data}) => data));
    }
}
