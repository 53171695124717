import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    ingestSkillGraphTemplatePath,
    FILENAME,
    IParsedCsvObject,
    IFileObject,
    Filenames,
    IStringObject,
    skillCsvRealizeHeaders,
    skillCsvSavvyHeaders,
    skillRelationCsvRealizeHeaders,
    skillRelationCsvSavvyHeaders,
    MultiCheckDropdown,
    skillGraphSavvyHeaders,
    skillGraphHeaders,
    skillGraphName,
    FileFormControl,
    DefaultVersionValue,
    loadingText,
} from '../../core/model/ingest-skill-graph.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SavvyAdminService } from '../../core/savvy-admin/savvy-admin.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PAGE_SIZE, CSV_DELIMITER, TranslationServiceReturnType } from '../../core/model/savvy-admin.model';
import { TranslationService } from '../../core/services/translation/translation.service';
import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { parse as json2csvParser } from 'json2csv';
import { ConvertRealizeFilesService } from './../../core/services/convert-realize-files/convert-realize-files.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GetSyncedProgramsGetSyncedProgramsFilter as FilterableSyncedPrograms } from '../../types/programs.components.types';
import { ModalService } from '../../core/services/modal-service/modal.service';
import { IError, ModalMessage } from '../../core/model/convert-realize-files.model';
import { ModalEventData, ModalEventPayload, ModalEventType } from '../../core/model/dialog.model';
@Component({
    selector: 'app-ingest-skill-graph',
    templateUrl: './ingest-skill-graph.component.html',
    styleUrls: ['./ingest-skill-graph.component.scss'],
})
export class IngestSkillGraphComponent implements AfterViewInit, OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    files: IFileObject = {};
    parsedCsvs: IParsedCsvObject = {};
    displayedColumns!: string[];
    tableData = new MatTableDataSource();
    isShowCsvTable = false;
    translateValues: TranslationServiceReturnType = {};
    previousFileViewed: string | undefined;
    isUploading = false;
    skillsObject: IStringObject[] | null = null;
    skillsFile: File | null = null;
    pageSize: number[] = PAGE_SIZE;
    syncedPrograms: FilterableSyncedPrograms[] = [];
    minTextLength = MultiCheckDropdown.dropdownMinTextLength;
    maxHeight = MultiCheckDropdown.dropdownMaxHeight;
    isProgramSelected = false;
    skillToSkillRelationObj: IStringObject[] | null = [];
    skillToSkillRelationFile: File | null = null;
    ingestSkillGraphForm = new FormGroup({
        skillGraphName: new FormControl('', Validators.required),
    });
    selectedProgramIds: number[] = [];
    selectedPrograms = [];
    noDataLabel = '';
    //? shouldMountDropdown is used to mount/unmount programs dropdown to clear dropdown's internal states
    shouldMountDropdown = false; 

    @ViewChild('skillFileInput') skillFileInput!: ElementRef;
    @ViewChild('skillRelationFileInput') skillRelationFileInput!: ElementRef;
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    fileError: IError = { isErrorOccurred: false };
    isWrongFileUploaded = false;
    isValidCsv = false;
    isSkillRelationFileUploaded = false;
    isSkillFileUploaded = false;

    constructor(
        private readonly savvyAdminService: SavvyAdminService,
        private readonly translationService: TranslationService,
        private readonly ngxCsvParser: NgxCsvParser,
        private readonly convertRealizeFileService: ConvertRealizeFilesService,
        private readonly modalService: ModalService,
        private readonly changeDetector: ChangeDetectorRef,
    ) {}

    async ngOnInit(): Promise<void> {
        await this.getTranslatedConstants();
        this.subscribeSyncedProgram();
        this.subscribeToIsSkillGraphIngested();
        this.subscribeToIsErrorOccurred();
        this.subscribeToFileErrorOccurred();
        this.subscribeToSkillRelationObj();
        this.subscribeToSkillObj();
        this.subscribeToModalCloseEvent();
        this.shouldMountDropdown = true;
    }

    subscribeSyncedProgram(): void {
        this.noDataLabel = this.translateValues[loadingText];
        this.savvyAdminService.getSyncedPrograms$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((syncedPrograms: FilterableSyncedPrograms[]) => {
                this.syncedPrograms = syncedPrograms;
            });
        this.savvyAdminService.loadSyncedPrograms();
    }

    subscribeToModalCloseEvent(): void{
        this.modalService.modalCloseEvent$
            .pipe(takeUntil(this.destroyed$))
            .subscribe(event => {
                if (event.eventType === ModalEventType.ERROR) {
                    if (event.data && event.data.fileName) {
                        event.data.fileName === FILENAME.skills ? this.resetSkillFile() : this.resetSkillRelationFile();
                        this.changeDetector.detectChanges();
                    }
                }
        });
    }

    onProgramSelectionValueChange(event: Event): void {
        const { detail } = event as CustomEvent<Array<string | number>>;
        this.selectedProgramIds = detail.map(detailValue =>
            typeof detailValue === 'string' ? parseInt(detailValue) : detailValue);
        this.isProgramSelected = !!detail.length;
    }

    ngAfterViewInit() {
        this.tableData.paginator = this.paginator;
        this.savvyAdminService.setIsLoading(false);
    }

    get templateUrl(): string {
        return ingestSkillGraphTemplatePath;
    }

    get isUploadBtnEnabled(): boolean {
        const isSkillGraphNameEntered = !!(this.ingestSkillGraphForm.get(FileFormControl.SKILL_GRAPH) as FormControl).value
        return this.isSkillFileUploaded && this.isSkillRelationFileUploaded && this.isProgramSelected && isSkillGraphNameEntered;
    }

    get isRealizeSkillCsv(): boolean {
        const parsedCsv = this.parsedCsvs[FILENAME.skills];
        if (parsedCsv) {
            const headers = this.convertRealizeFileService.extractHeaders(parsedCsv);
            return skillCsvRealizeHeaders.every((header) => headers.includes(header));
        }
        return false;
    }

    get isRealizeSkillRelationCsv(): boolean {
        const parsedCsv = this.parsedCsvs[FILENAME.skillRelation];
        if (parsedCsv) {
            const headers = this.convertRealizeFileService.extractHeaders(parsedCsv);
            return skillRelationCsvRealizeHeaders.every((header) => headers.includes(header));
        }
        return false;
    }

    get filenames(): Filenames {
        return FILENAME;
    }

    getFileName(key: string): string | null {
        if (this.files[key] && this.files[key].name) {
            return this.files[key].name;
        }
        return null;
    }

    resetAfterSubmit(): void {
        (this.ingestSkillGraphForm.get(FileFormControl.SKILL_GRAPH) as FormControl).setValue('');
        this.resetSkillFile();
        this.resetSkillRelationFile();
        this.resetTableView();
        this.resetMultiCheckProgramDropDown();
    }

    resetMultiCheckProgramDropDown(): void {
        this.selectedPrograms = [];
        this.selectedProgramIds = [];
        this.isProgramSelected = false;
    }

    resetSkillFile(): void {
        this.skillFileInput.nativeElement.value = '';
        this.skillsObject = null;
        this.skillsFile = null;
        this.files[FILENAME.skills] = new File([], '');
        this.parsedCsvs[FILENAME.skills] = null;
        this.isSkillFileUploaded = false;
    }

    resetSkillRelationFile(): void {
        this.skillRelationFileInput.nativeElement.value = '';
        this.skillToSkillRelationFile = null;
        this.skillToSkillRelationObj = null;
        this.parsedCsvs[FILENAME.skillRelation] = null;
        this.files[FILENAME.skillRelation] = new File([], '');
        this.isSkillRelationFileUploaded = false;
    }

    resetTableView(): void {
        this.isShowCsvTable = false;
        this.displayedColumns = [];
        this.tableData.data = [];
    }

    selectFile(event: Event): void {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        if (target && target.id && target.files && target.files.length) {
            if (target.id === this.previousFileViewed) {
                this.resetTableView();
            }
            this.files[target.id] = target.files[0];
            this.parseCsv(target.files[0], target.id);
        }
    }

    parseCsv(file: File, fileName: string): void {
        this.ngxCsvParser
            .parse(file, { header: true, delimiter: CSV_DELIMITER })
            .pipe(takeUntil(this.destroyed$))
            .subscribe(
                (result) => {
                    this.parsedCsvs[fileName] = result as IStringObject[];
                    const requiredHeaders = this.getRequiredCsvHeaders(fileName);
                    this.isValidCsv = this.convertRealizeFileService.validateCsv(
                        result as IStringObject[],
                        requiredHeaders,
                        fileName
                    );
                    if (this.isValidCsv) {
                        if (fileName === FILENAME.skills) {
                            this.isSkillFileUploaded = true;
                        }
                        if (fileName === FILENAME.skillRelation) {
                            this.isSkillRelationFileUploaded = true;
                        }
                        this.mapSkillCsv(result as IStringObject[], fileName);
                    }
                },
                (error: NgxCSVParserError) => {
                    const modalEventPayload: ModalEventPayload = this.getModalEventPayload(
                        ModalEventType.ERROR,
                        this.translateValues[ModalMessage.FAILED_TO_READ],
                        { fileName }
                    );
                    this.modalService.openDialog(modalEventPayload);
                }
            );
    }

    getSkillRelationRequiredHeaders(): string[] {
        if (this.isRealizeSkillRelationCsv) {
            return skillRelationCsvRealizeHeaders;
        }
        return skillRelationCsvSavvyHeaders;
    }

    getSkillRequiredHeaders(): string[] {
        if (this.isRealizeSkillCsv) {
            return skillCsvRealizeHeaders;
        }
        return skillCsvSavvyHeaders;
    }

    getRequiredCsvHeaders(fileName: string): string[] {
        switch (fileName) {
            case skillGraphName:
                return skillGraphHeaders;
            case FILENAME.skillRelation:
                return this.getSkillRelationRequiredHeaders();
            case FILENAME.skills:
                return this.getSkillRequiredHeaders();
            default:
                return [];
        }
    }

    async getTranslatedConstants(): Promise<void> {
        this.translateValues = (await this.translationService.get([
            loadingText,
            ModalMessage.WRONG_FILE,
            ModalMessage.FAILED_TO_READ,
            ModalMessage.SUCCESS_MESSAGE_INITIAL,
            ModalMessage.SUCCESS_MESSAGE_END,
            ModalMessage.ERROR_MESSAGE,
            ModalMessage.INCORRECT_SKILL_HEADERS,
            ModalMessage.INCORRECT_SKILL_RELATION_HEADERS,
        ])) as TranslationServiceReturnType;
    }

    subscribeToIsSkillGraphIngested(): void {
        this.savvyAdminService.isSkillGraphIngested$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((status) => {
                if (status) {
                    this.setIsUploadingFalseAndShowModal(
                        `${this.translateValues[ModalMessage.SUCCESS_MESSAGE_INITIAL]} "${
                            this.savvyAdminService.ingestedSkillGraphName
                        }" ${this.translateValues[ModalMessage.SUCCESS_MESSAGE_END]} ${
                            this.savvyAdminService.ingestedSkillGraphId
                        }`
                    );
                    this.resetAfterSubmit();
                }
        });
    }

    subscribeToIsErrorOccurred(): void {
        this.savvyAdminService.isErrorOccurred$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
            if (status) {
                this.savvyAdminService.isErrorCodeNaN
                    ? this.setIsUploadingFalseAndShowModal(this.translateValues[ModalMessage.ERROR_MESSAGE])
                    : this.setIsUploadingFalseAndShowModal(this.savvyAdminService.errorMessage);
            }
        });
    }

    subscribeToSkillRelationObj(): void {
        this.convertRealizeFileService.skillRelation$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((data: IStringObject[]) => {
                if (data.length) {
                    this.skillToSkillRelationObj = data;
                    this.skillToSkillRelationFile = this.csvObjectToCsvFile(
                        this.skillToSkillRelationObj,
                        FILENAME.skillRelation
                    );
                }
            });
    }

    subscribeToSkillObj(): void {
        this.convertRealizeFileService.skills$.pipe(takeUntil(this.destroyed$)).subscribe((data: IStringObject[]) => {
            if (data.length) {
                this.skillsObject = data;
                this.skillsFile = this.csvObjectToCsvFile(this.skillsObject, FILENAME.skills);
            }
        });
    }

    subscribeToFileErrorOccurred(): void {
        this.convertRealizeFileService.isConvertFileErrorOccurred$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((error: IError) => {
                if (error.isErrorOccurred) {
                    this.fileError = error;
                    const modalEventPayload: ModalEventPayload = this.getModalEventPayload(
                        ModalEventType.ERROR,
                        this.translateValues[error.errorMessage as string],
                        error.data
                    );
                    this.modalService.openDialog(modalEventPayload);
                }
            });
    }

    private getModalEventPayload(eventType: ModalEventType, message: string, data?: ModalEventData): ModalEventPayload {
        let modalEventPayload: ModalEventPayload = {
            eventType,
            message,
        }
        if (data) {
            modalEventPayload = { ...modalEventPayload, data };
        }
        return modalEventPayload;
    }

    mapSkillCsv(parsedCsv: IStringObject[], filename: string): void {
        if (this.isRealizeSkillCsv && filename === FILENAME.skills) {
            this.convertRealizeFileService.convertRealizeSkillOrRelationFiles(parsedCsv, filename);
        }
        if (this.isRealizeSkillRelationCsv && filename === FILENAME.skillRelation) {
            this.convertRealizeFileService.convertRealizeSkillOrRelationFiles(parsedCsv, filename);
        }
    }

    csvObjectToCsvFile(csvObject: IStringObject[], filename: string): File {
        let fields = [''];
        if (filename === skillGraphName) {
            fields = skillGraphHeaders;
        }
        if (filename === FILENAME.skills) {
            fields = skillCsvSavvyHeaders;
        }
        if (filename === FILENAME.skillRelation) {
            fields = skillRelationCsvSavvyHeaders;
        }
        const opts = { fields };
        const csvString = json2csvParser(csvObject, opts);
        return new File([csvString], filename);
    }

    setValuesIntoTable(key: string, resetView = true): void {
        if (resetView && this.isThisFileBeingViewed(key)) {
            this.isShowCsvTable = false;
            return;
        }
        this.isShowCsvTable = true;
        this.previousFileViewed = key;

        let parsedCsv: IStringObject[] | null = this.parsedCsvs[key];
        if (this.isRealizeSkillCsv && key === FILENAME.skills) {
            parsedCsv = this.skillsObject;
        }
        if (this.isRealizeSkillRelationCsv && key === FILENAME.skillRelation) {
            parsedCsv = this.skillToSkillRelationObj;
        }
        if (parsedCsv?.length) {
            const headers = this.convertRealizeFileService.extractHeaders(parsedCsv);
            this.displayedColumns = headers;
            this.tableData.data = parsedCsv;
        }
    }

    isThisFileBeingViewed(key: string): boolean {
        return this.isShowCsvTable && key === this.previousFileViewed;
    }

    createSkillGraph(): File {
        const skillGraphFormName = (this.ingestSkillGraphForm.get(FileFormControl.SKILL_GRAPH) as FormControl).value;
        const programNames = this.selectedProgramIds.map((id) => {
            return this.syncedPrograms.find((program) => program.id === id)?.name as string;
        });
        const skillGraphObjArray: IStringObject[] = [];
        programNames.forEach((programName) => {
            const skillGraphObj: IStringObject = {
                [skillGraphSavvyHeaders.NAME]: skillGraphFormName,
                [skillGraphSavvyHeaders.VERSION]: DefaultVersionValue,
                [skillGraphSavvyHeaders.PROGRAM_NAME]: programName,
            };
            skillGraphObjArray.push(skillGraphObj);
        });
        return this.csvObjectToCsvFile(skillGraphObjArray, skillGraphName);
    }

    upload(): void {
        this.isUploading = true;
        const skillGraph = this.createSkillGraph();
        const skillCsvFile =
            this.isRealizeSkillCsv && this.skillsFile ? this.skillsFile : (this.files[FILENAME.skills]);
        const skillRelation =
            this.isRealizeSkillRelationCsv && this.skillToSkillRelationFile
                ? this.skillToSkillRelationFile
                : (this.files[FILENAME.skillRelation]);

        this.savvyAdminService.ingestSkillGraph(skillGraph, skillCsvFile, skillRelation, this.selectedProgramIds);
    }

    setIsUploadingFalseAndShowModal(message: string): void {
        this.isUploading = false;
        const modalEventPayload: ModalEventPayload = this.getModalEventPayload(
            ModalEventType.ERROR,
            message
        );
        this.modalService.openDialog(modalEventPayload);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.convertRealizeFileService.resetSkillRelation();
        this.convertRealizeFileService.resetSkills();
        this.convertRealizeFileService.resetConvertFileErrorOccurred();
        this.savvyAdminService.setIsErrorOccurred(false);
        this.shouldMountDropdown = false;
    }
}
