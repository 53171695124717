import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnDestroy {
    layoutClass!: string;

    @HostBinding('class') class = 'app-drawer';
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    isErrorRoute = false;

    constructor(
        private readonly route: ActivatedRoute,
        ) {
            this.route.url
            .pipe(takeUntil(this.destroyed$))
            .subscribe((urlSegment) => {
                this.isErrorRoute =
                    urlSegment[0] && urlSegment[0].path === 'error';
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
