import gql from 'graphql-tag';

export const getSkillGraphs = gql`
    query getSkillGraphs {
        getSkillGraphs {
            id
            dateCreated
            dateUpdated
            isActive
            name
            version
        }
    }
`;
