export const RBS_TOKEN = 'realize.rbs_token';
export const PROFILE_TOKEN = 'profile.token';
export const PAGE_SIZE = [10, 20, 50];
export const CSV_DELIMITER = ','

export enum UserRoles {
    PearsonAdmin = 'Pearson Admin',
}

export enum SnackBar {
    AUTH_GUARD_MESSAGE = 'savvy-admin-auth-guard.snack-bar.message',
}

export enum DateFormat {
    FORMAT = 'MM/dd/YYYY hh:mm:ss',
    LOCALE = 'en-Us',
}

export enum TimeOutDuration {
    THREE_SECOND = 3000,
    SIX_SECOND = 6000,
}

export interface SkillGraphsOption {
    value: number | string;
    viewValue: string;
}

export interface UpdateProgramModalData {
    productId: number;
    productName: string;
    activeSkillGraphId: number;
    activeSkillGraphName: string;
    isActive: boolean;
}

export interface PreviousUpdateProgramValues {
    previousActiveSkillGraphId: number;
    previousIsActive: boolean;
    previousProductName: string;
}

export type TranslationServiceReturnType = {
    [key: string]: string;
};

export enum downloadButtonIconName {
    FILE_DOWNLOAD = 'file_download',
    AUTORENEW = 'autorenew'
}
