import gql from 'graphql-tag';

export const registerProgram = gql`
    mutation registerProgram($grades: [Int!], $productName: String!, $programName: String!) {
        registerProgram(grades: $grades, productName: $productName, programName: $programName) {
            id
            dateCreated
            dateUpdated
            isActive
            uri
            partnerId
            externalId
            realmId
            name
            grades
            product
            activeSkillGraphId
            activeSkillGraphName
        }
    }
`;
