<app-loader [loaderText]="loaderLabel"></app-loader>
<div class="programs-wrapper">
    <div class="programs-header">
        <input
            #searchProgram
            type="text"
            class="mat-elevation-z2"
            placeholder="Search Programs"
            (keyup)="applyFilter()"
        />
        <button mat-raised-button color="primary" (click)="openRegisterProgramModal()">{{ 'programs.register-program-button' | translate }}</button>
    </div>
    <div class="programs-body">
        <div class="mat-elevation-z2">
            <table aria-describedby="(synced-programs | translate)" mat-table matSort [dataSource]="syncedPrograms">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="name">{{ 'programs.table.name' | translate }}</th>
                    <td mat-cell *matCellDef="let program">{{ program.name }}</td>
                </ng-container>

                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="product">
                        {{ 'programs.table.product' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.product }}</td>
                </ng-container>

                <ng-container matColumnDef="isActive">
                    <th class="mat-header-center" mat-header-cell *matHeaderCellDef mat-sort-header id="isActive">{{ 'programs.table.active' | translate }}</th>
                    <td mat-cell *matCellDef="let program">{{ program.isActive }}</td>
                </ng-container>

                <ng-container matColumnDef="activeSkillGraphName">
                    <th class="mat-header-center" mat-header-cell *matHeaderCellDef mat-sort-header id="activeSkillGraphName">
                        {{ 'programs.table.skill-graph-name' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.activeSkillGraphName || '-' }}</td>
                </ng-container>

                <ng-container matColumnDef="download">
                    <th mat-header-cell *matHeaderCellDef id="download">{{ 'programs.table.download-skill-graph' | translate }}</th>
                    <td mat-cell *matCellDef="let program;">
                        <button mat-icon-button [ngClass]="program.downloadButtonClicked ? 'mat-download-button-clicked' : 'mat-download-button'" (click)="downloadSkillGraph(program.id, program.activeSkillGraphId)">
                            <mat-icon class="material-icons-outlined">{{ program.downloadButtonIconName }}</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="dateCreated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="dateCreated">
                        {{ 'programs.table.created-date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.dateCreated | date: 'MM/dd/yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="dateUpdated">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="dateUpdated">
                        {{ 'programs.table.updated-date' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let program">{{ program.dateUpdated | date: 'MM/dd/yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef id="action">{{ 'programs.table.edit' | translate }}</th>
                    <td mat-cell *matCellDef="let program">
                        <button (click)="openUpdateProgramModal(program.id, program.product, program.activeSkillGraphId, program.activeSkillGraphName, program.isActive)" mat-icon-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="pageSize" showFirstLastButtons> </mat-paginator>
        </div>
    </div>
</div>
