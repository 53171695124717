import { parse as json2csvParser } from 'json2csv';

export function convertArrayToCSV(data: any[], fields: any[]): string {
    const opts = { fields };
    return json2csvParser(data, opts);
}

export function downloadFile(csvString: string, fileName: string): void {
    const blob = new Blob([csvString], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
}

export function getCurrentDate(): string {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month starts from 0, so we add 1
    const day = String(date.getDate()).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${month}_${day}_${year}`;
}