import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProgramsComponent } from './pages/programs/programs.component';
import { IngestSkillGraphComponent } from './pages/ingest-skill-graph/ingest-skill-graph.component';
import { ErrorComponent } from './pages/error/error.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { LstAuthModule, LstAuthModuleOptions } from '@savvaslearning/lst-auth';
import { environment } from '../environments/environment';
import { HowToComponent } from './pages/how-to/how-to.component';
import { CheckPoolCountComponent } from './pages/check-pool-count/check-pool-count.component';

const lstModuleOptions: LstAuthModuleOptions = {
    appId: environment.appId,
    authGatewayBaseUrl: environment.authGatewayUrl,
    authContextId: environment.authContextId,
    authGatewayClientId: environment.rbsConfig.clientId,
    rbsTokenScope: environment.rbsConfig.scope,
    ssoBaseUrl: environment.ssoUrl,
    rbsTokenGrantType: environment.rbsConfig.grant_type,
    loginQueryParams: environment.ssoLoginQueryParams,
    errorRoute: environment.errorRoute,
    authCallbackRoute: environment.authCallbackRoute,
};

@NgModule({
    declarations: [AppComponent, ProgramsComponent, IngestSkillGraphComponent, ErrorComponent, HowToComponent, CheckPoolCountComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CoreModule,
        SharedModule,
        LstAuthModule.forRoot(lstModuleOptions),
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
})
export class AppModule {}
