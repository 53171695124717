import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule } from '@angular/material/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoaderComponent } from './components/loader/loader.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdateProgramModalComponent } from './components/update-program/update-prorgam-modal.component';
import { RegisterProgramModalComponent } from './components/register-program-modal/register-program-modal.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { AppRoutingModule } from '../app-routing.module';
import { DrawerComponent } from './components/drawer/drawer.component';

@NgModule({
    declarations: [
    LoaderComponent,
    UpdateProgramModalComponent,
    RegisterProgramModalComponent,
    DrawerComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatDialogModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatSelectModule,
        MatRippleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatAutocompleteModule,
        NgxCsvParserModule,
        AppRoutingModule,
    ],
    exports: [
        TranslateModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatDialogModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatInputModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatRippleModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatAutocompleteModule,
        LoaderComponent,
        NgxCsvParserModule,
        DrawerComponent,
    ],
})
export class SharedModule {}
