import { DialogOptions } from "@savvaslearning/cel-components";

export enum ModalEventType {
    SUCCESS = "success",
    ERROR = "error",
  }

  export interface ModalEventData {
    [key: string]: string;
  }

  export interface IDialog {
    create(options: DialogOptions): Promise<void>,
    dismiss(id?: string): Promise<void>,
    present(): Promise<void>,
  }
  
  export interface ModalEventPayload {
    eventType: ModalEventType,
    data?: ModalEventData,
    message: string,
  }
