export interface PoolCountParameterOption {
    Title: string;
    id: string;
}

export const CheckPoolCountParameterOptions: PoolCountParameterOption[] = [
    {
        Title: 'check-pool-count.parameters.assignments-with-zero-questions',
        id: '0_questions'
    },
    {
        Title: 'check-pool-count.parameters.assignments-with-less-than-eight-questions',
        id: 'less_than_8_questions'
    },
    {
        Title: 'check-pool-count.parameters.assignments-between-eight-and-fifteen-questions',
        id: 'between_8_15_questions'
    },
    {
        Title: 'check-pool-count.parameters.assignments-with-more-than-fifteen-questions',
        id: 'greater_than_15_questions'
    },
    {
        Title: 'check-pool-count.parameters.assignments-with-missing-skills',
        id: 'missing_skills'
    },
    {
        Title: 'check-pool-count.parameters.assignments-mapped-to-skills',
        id: 'more_than_4_skills'
    },
];

export const ItemPoolCountCsvHeaders = [
    {
        label: 'Program id', 
        value: 'programId', 
        default: 'NULL'
    },
    {
        label: 'Program name', 
        value: 'programName', 
        default: 'NULL'
    },
    {
        label: 'Content item id', 
        value: 'contentItemId', 
        default: 'NULL'
    },
    {
        label: 'Version', 
        value: 'version', 
        default: 'NULL'
    },
    {
        label: 'External Id', 
        value: 'externalId', 
        default: 'NULL'
    },
    {
        label: 'assessment pool count', 
        value: 'poolCount', 
        default: 'NULL'
    },
    {
        label: 'number of skills mapped', 
        value: 'numberOfSkillsMapped', 
        default: 'NULL'
    },
    {
        label: 'Skill Codes',
        value: 'skillCodes',
        default: 'NULL'
    }
];
