import gql from 'graphql-tag';

export const getSkillGraphCSVs = gql`
    query getSkillGraphCSVs($skillGraphId: Int!) {
        getSkillGraphCSVs(skillGraphId: $skillGraphId) {
            skillGraph {
                name
                version
                program_name: programName
            }
            skillSkill {
                prerequisite_skill_fullpath: prerequisiteSkillFullpath
                postrequisite_skill_fullpath: postrequisiteSkillFullpath
                strength
            }
        }
    }
`;
