import gql from 'graphql-tag';

export const updateProgram = gql`
    mutation updateProgram($isActive: Boolean!, $productName: String!, $activeSkillGraphId: Int, $programId: Int!, $previousIsActive: Boolean!, $previousProductName: String!, $previousActiveSkillGraphId: Int) {
        updateProgram(
            isActive: $isActive
            productName: $productName
            activeSkillGraphId: $activeSkillGraphId
            programId: $programId
            previousIsActive: $previousIsActive
            previousProductName: $previousProductName
            previousActiveSkillGraphId: $previousActiveSkillGraphId
        ) {
            id
            dateCreated
            dateUpdated
            isActive
            uri
            partnerId
            externalId
            realmId
            name
            grades
            product
            activeSkillGraphId
            activeSkillGraphName
        }
    }
`;
