import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import {
    PreviousUpdateProgramValues,
    SkillGraphsOption,
    TimeOutDuration,
    TranslationServiceReturnType,
    UpdateProgramModalData,
} from '../../../core/model/savvy-admin.model';
import { SavvyAdminService } from '../../../core/savvy-admin/savvy-admin.service';
import { ProgramsComponent } from '../../../pages/programs/programs.component';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { getSkillGraphs_getSkillGraphs as SkillGraphs } from './../../../../../schema/graphql-types';

@Component({
    selector: 'app-update-program-modal',
    templateUrl: './update-prorgam-modal.component.html',
    styleUrls: ['./update-prorgam-modal.component.scss'],
})
export class UpdateProgramModalComponent implements OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    isProgramUpdationInProgress = false;
    isDataLoaded = this.savvyAdminService.isSkillGraphs$;
    translateValues: TranslationServiceReturnType = {};
    updateProgramForm = new FormGroup({
        activeSkillGraph: new FormControl(''),
        productName: new FormControl(''),
        isActive: new FormControl(false),
    });
    skillGraphsOptions: SkillGraphsOption[] = [];
    filteredSkillGraphsOptions: Observable<SkillGraphsOption[]> = of([]);
    previousUpdateProgramModalValues!: PreviousUpdateProgramValues;

    constructor(
        private readonly savvyAdminService: SavvyAdminService,
        private readonly dialogRef: MatDialogRef<ProgramsComponent>,
        private readonly _snackBar: MatSnackBar,
        private readonly translationService: TranslationService,
        @Inject(MAT_DIALOG_DATA) public data: UpdateProgramModalData
    ) {}

    async ngOnInit() {
        const {
            activeSkillGraphId,
            activeSkillGraphName,
            isActive,
            productName,
        } = this.data;
        this.translateValues = (await this.translationService.get([
            'update-program.snack-bar.message',
            'error.message',
        ])) as TranslationServiceReturnType;
        this.storePreviousProgramValuesForLogging(
            activeSkillGraphId,
            isActive,
            productName
        );
        this.updateProgramForm.patchValue({
            productName: productName,
            activeSkillGraph: {
                value: activeSkillGraphId ?? '',
                viewValue: activeSkillGraphName
                    ? activeSkillGraphName + `(${activeSkillGraphId})`
                    : '',
            },
            isActive: isActive,
        });

        this.savvyAdminService.isUpdateProgramCompleted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((status) => {
                if (status) {
                    this.closeTheDialogAndShowSnackBar(
                        this.translateValues['update-program.snack-bar.message']
                    );
                }
            });
        this.savvyAdminService.isErrorOccurred$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((status) => {
                if (status) {
                    this.closeTheDialogAndShowSnackBar(
                        this.translateValues['error.message']
                    );
                }
            });

        this.savvyAdminService.getSkillGraphs$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((skillGraphs) => {
                this.skillGraphsOptions = skillGraphs.map(
                    (skillGraph: SkillGraphs) => {
                        let viewValue = skillGraph.name;
                        viewValue += `(${skillGraph.id})`;
                        return {
                            value: skillGraph.id,
                            viewValue,
                        };
                    }
                );
            });

        this.filteredSkillGraphsOptions = this.updateProgramForm
            .get('activeSkillGraph')
            ?.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value))
            ) as any;
    }

    private _filter(value: string): SkillGraphsOption[] {
        const filterValue =
            typeof value === 'string' ? value.toLowerCase() : value;

        return this.skillGraphsOptions.filter((option) =>
            option.viewValue.toLowerCase().includes(filterValue)
        );
    }

    autoCompleteDisplay(selection: SkillGraphsOption): string {
        return selection.viewValue;
    }

    closeTheDialogAndShowSnackBar(snackBarMessage: string): void {
        this.dialogRef.close();
        this.isProgramUpdationInProgress = false;
        this._snackBar.open(snackBarMessage, '', {
            duration: TimeOutDuration.THREE_SECOND,
        });
        this.savvyAdminService.setIsErrorOccurred(false);
    }

    updateProgram(): void {
        this.isProgramUpdationInProgress = true;
        const {
            productName,
            activeSkillGraph: { value: activeSkillGraphId },
            isActive,
        } = this.updateProgramForm.value;
        const {
            previousActiveSkillGraphId,
            previousIsActive,
            previousProductName,
        } = this.previousUpdateProgramModalValues;
        this.savvyAdminService.updateProgram(
            isActive,
            this.data.productId,
            productName,
            activeSkillGraphId,
            previousActiveSkillGraphId,
            previousIsActive,
            previousProductName
        );
    }

    storePreviousProgramValuesForLogging(
        previousActiveSkillGraphId: number,
        previousIsActive: boolean,
        previousProductName: string
    ): void {
        this.previousUpdateProgramModalValues = {
            previousActiveSkillGraphId,
            previousIsActive,
            previousProductName,
        };
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
