import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from '../../core/savvy-admin/savvy-admin.service';
import { GetSyncedProgramsGetSyncedProgramsFilter as FilterableSyncedPrograms } from '../../types/programs.components.types';
import { CheckPoolCountParameterOptions, PoolCountParameterOption, ItemPoolCountCsvHeaders } from 'src/app/core/model/check-pool-count.model';
import { FormControl } from '@angular/forms';
import { convertArrayToCSV, downloadFile, getCurrentDate } from 'src/app/shared/sharedUtils';
import { TranslationService } from 'src/app/core/services/translation/translation.service';
import { TranslationServiceReturnType } from 'src/app/core/model/savvy-admin.model';


@Component({
    selector: 'app-check-pool-count',
    templateUrl: './check-pool-count.component.html',
    styleUrls: ['./check-pool-count.component.scss'],
})
export class CheckPoolCountComponent implements OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    translateValues: TranslationServiceReturnType = {};
    syncedPrograms: FilterableSyncedPrograms[] = [];
    parameterDropdownOptions: PoolCountParameterOption[] = [];

    programSelectFormControl = new FormControl('');
    filteredSyncedPrograms: Observable<FilterableSyncedPrograms[]> | null = null
    disableDownloadBtn = true;
    loaderLabel = '';

    selectedProgramId = 0;
    selectedProgram: FilterableSyncedPrograms | null = null;
    selectedParameter: PoolCountParameterOption = {
        Title: '',
        id: ''
    };
    selectedProgramToolTip = '';

    displayZeroState = false;

    isValidProgramName = false;

    constructor(private readonly savvyAdminService: SavvyAdminService,  private readonly translationService: TranslationService,) {}

    async ngOnInit() {
        this.translateValues = (await this.translationService.get([
            'programs.loader-text'
        ])) as TranslationServiceReturnType;
        this.subscribeToSyncedPrograms();
        this.getParameterDropdowns();
        this.subscribeToGetItemCountCSVData();
    }

    getFilteredPrograms(): void {
        this.filteredSyncedPrograms = this.programSelectFormControl.valueChanges.pipe(
            startWith(''),
            map(programName => {
                if (!this.checkIfValidProgram(programName)) {
                    this.disableDownloadBtn = true;
                    this.isValidProgramName = false;
                } 
                if (programName === '') {
                    this.resetSelectedProgram();
                    this.disableDownloadBtn = true;
                }
                this.selectedProgramToolTip = programName;
                return(this._filter(programName || ''));
                }
            )
        );
    }

    checkIfValidProgram(programName: string | null): boolean {
        if (!programName) {
            return false
        }
        const isProgramNameValid = this.syncedPrograms.filter(
            program => program.name === programName 
        ).length > 0;
        return isProgramNameValid;
    }

    resetSelectedProgram(): void {
        this.selectedProgram = null;
        this.selectedProgramId = 0;
        this.selectedProgramToolTip = '';
    }

    getParameterDropdowns(): void {
        this.parameterDropdownOptions = CheckPoolCountParameterOptions
    }

    subscribeToSyncedPrograms(): void {
        this.savvyAdminService.getSyncedPrograms$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((syncedPrograms: FilterableSyncedPrograms[]) => {
            this.syncedPrograms = syncedPrograms;
            this.getFilteredPrograms();
        });
        this.savvyAdminService.loadSyncedPrograms();
    }

    subscribeToGetItemCountCSVData(): void {
        this.savvyAdminService.getItemPoolCountCSVData$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((itemPoolCountData) => {
                if (itemPoolCountData.getItemPoolCountCSVs.length > 0) {
                    const itemCountCSVData = convertArrayToCSV(
                        itemPoolCountData.getItemPoolCountCSVs,
                        ItemPoolCountCsvHeaders
                    );
                    if (this.selectedProgram) {
                        downloadFile(
                            itemCountCSVData,
                            `${this.selectedProgram.name}_${this.selectedParameter.id}_${getCurrentDate()}.csv`
                        );
                    }
                } else {
                    this.displayZeroState = true;
                    this.disableDownloadBtn = true;
                }
            })
    }

    downloadItemCountCSVData(programId: number, parameter: string): void {
        this.loaderLabel = this.translateValues['programs.loader-text'];
        this.displayZeroState = false;
        this.savvyAdminService.getItemPoolCountCSVData(programId, parameter);
    }

    private _filter(programName: string): FilterableSyncedPrograms[] {
        const filterValue = programName.toLowerCase();
        if (this.syncedPrograms) {
            return this.syncedPrograms.filter(program => program.name.toLowerCase().includes(filterValue));
        } else {
            return [];
        }
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    setSelectedParameter(parameter: PoolCountParameterOption, event: any): void {
        // isUserInput  is a true or false value
        // it is true when the event is triggered by the user selecting a new program
        // it is false when the event is triggered from the deselection of the previously selected program
        
        if (event.isUserInput) {
            this.selectedParameter = parameter;
            this.checkDownloadBtn();
        }
    }

    setSelectedProgram(program: FilterableSyncedPrograms, event: any): void {
        // isUserInput  is a true or false value
        // it is true when the event is triggered by the user selecting a new program
        // it is false when the event is triggered from the deselection of the previously selected program

        if (event.isUserInput) {
            this.selectedProgram = program;
            this.selectedProgramId = program.id;
            this.isValidProgramName = true;
            this.checkDownloadBtn();
        }
    }

    checkDownloadBtn(): void {
        if (this.selectedParameter.id !== '' && this.selectedProgram !== null && this.isValidProgramName) {
            this.disableDownloadBtn = false;
        } else {
            this.disableDownloadBtn = true;
        }
    }
}
