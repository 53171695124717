import { Component, OnDestroy, OnInit } from '@angular/core';
import { LstAuthStateService } from '@savvaslearning/lst-auth';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    isAuthenticated = false;

    constructor(private readonly authStateService: LstAuthStateService) {}

    ngOnInit(): void {
        this.authStateService.isAuthenticated$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isAuthenticated) => {
                this.isAuthenticated = isAuthenticated;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
