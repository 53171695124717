import { Component, OnInit } from '@angular/core';
import { SavvyAdminService } from '../../core/savvy-admin/savvy-admin.service';

@Component({
    selector: 'app-how-to',
    templateUrl: './how-to.component.html',
    styleUrls: ['./how-to.component.scss'],
})
export class HowToComponent implements OnInit {
    constructor(private readonly savvyAdminService: SavvyAdminService) {}

    async ngOnInit(): Promise<void> {
        this.savvyAdminService.setIsLoading(false);
    }
}
