import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from '../../core/savvy-admin/savvy-admin.service';
import { GetSyncedProgramsGetSyncedProgramsNewFilter as FilterableSyncedPrograms } from '../../types/programs.components.types';
import { MatDialog } from '@angular/material/dialog';
import { UpdateProgramModalComponent } from '../../shared/components/update-program/update-prorgam-modal.component';
import { RegisterProgramModalComponent } from '../../shared/components/register-program-modal/register-program-modal.component';
import { TranslationService } from '../../core/services/translation/translation.service';
import { formatDate } from '@angular/common';
import { skillGraphHeaders, skillRelationCsvSavvyHeaders } from '../../core/model/ingest-skill-graph.model';
import { DateFormat, PAGE_SIZE, downloadButtonIconName } from '../../core/model/savvy-admin.model';
import { convertArrayToCSV, downloadFile, getCurrentDate } from 'src/app/shared/sharedUtils';


@Component({
    selector: 'app-programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements AfterViewInit, OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    translateValues: { [key: string]: string } = {};
    choices = {
        yes: 'choices.yes',
        no: 'choices.no'
    };
    displayedColumns: string[] = [
        'name',
        'product',
        'isActive',
        'activeSkillGraphName',
        'download',
        'dateCreated',
        'dateUpdated',
        'action',
    ];
    syncedPrograms = new MatTableDataSource<FilterableSyncedPrograms>();
    defaultSyncedPrograms: FilterableSyncedPrograms[] = [];
    pageSize = PAGE_SIZE;
    loaderLabel = '';
    programId = 0;

    constructor(
        private readonly savvyAdminService: SavvyAdminService,
        private readonly dialog: MatDialog,
        private readonly translationService: TranslationService,
    ) { }

    @ViewChild(MatPaginator) paginator: MatPaginator | undefined;
    @ViewChild(MatSort) sort: MatSort | undefined;
    @ViewChild('searchProgram') input!: ElementRef;

    async ngOnInit() {
        this.translateValues = (await this.translationService.get([
            this.choices.yes,
            this.choices.no,
            'programs.loader-text'
        ])) as {
            [key: string]: string;
        };
        this.subscribeToIsErrorOccurred();
        this.applyFilterOnUpdateAndRegisterProgram();
        this.savvyAdminService.loadSkillGraphs();
        this.savvyAdminService.getSyncedPrograms$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((syncedPrograms) => {
                if (this.sort) {
                    this.sort.sort({ id: null as any, start: 'asc', disableClear: false });
                }
                const syncedProgramsData = syncedPrograms.map((programs) => {
                    return {
                        ...programs,
                        downloadButtonClicked: false,
                        downloadButtonIconName: downloadButtonIconName.FILE_DOWNLOAD,
                    };
                });
                this.syncedPrograms = new MatTableDataSource<FilterableSyncedPrograms>(syncedProgramsData);
                this.syncedPrograms.paginator = this.paginator as MatPaginator;
                this.syncedPrograms.sort = this.sort as MatSort;
                this.syncedPrograms.data = this.convertDataToString(
                    this.syncedPrograms.data
                );
                this.syncedPrograms.data = this.sortSyncedPrograms(
                    this.syncedPrograms.data
                );
                this.defaultSyncedPrograms = this.syncedPrograms.data;
                this.syncedPrograms.sortingDataAccessor = (item, property): string | number => {
                    switch (property) {
                      case 'dateCreated': return new Date(item.dateCreated).valueOf();
                      case 'dateUpdated': return new Date(item.dateUpdated).valueOf();
                      default: return (item as any)[property];
                    }
                };
            });
        this.savvyAdminService.loadSyncedPrograms();
        this.subscribeToGetSkillGraphCSVData();
    }

    subscribeToGetSkillGraphCSVData(): void {
        this.savvyAdminService.getSkillGraphCSVData$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((skillGraphData) => {
                const graphCSVData = convertArrayToCSV(
                    skillGraphData.skillGraph,
                    skillGraphHeaders
                );
                downloadFile(
                    graphCSVData,
                    `${
                        skillGraphData.skillGraph[0].name
                    }_skill-graph_${getCurrentDate()}.csv`
                );
                const skillCSVData = convertArrayToCSV(
                    skillGraphData.skillSkill,
                    skillRelationCsvSavvyHeaders
                );
                downloadFile(
                    skillCSVData,
                    `${
                        skillGraphData.skillGraph[0].name
                    }_skill-skill_${getCurrentDate()}.csv`
                );
                const programIndex = this.getProgramIndex(this.programId);
                if (
                    this.syncedPrograms.data[programIndex]
                        .downloadButtonIconName ===
                    downloadButtonIconName.AUTORENEW
                ) {
                    this.syncedPrograms.data[
                        programIndex
                    ].downloadButtonIconName =
                        downloadButtonIconName.FILE_DOWNLOAD;
                }
            });
    }

    downloadSkillGraph(programId: number, activeSkillGraphId: string): void {
        const skillGraphId = parseInt(activeSkillGraphId);
        this.programId = programId;
        const programIndex = this.getProgramIndex(programId);
        this.syncedPrograms.data[programIndex].downloadButtonClicked = true;
        this.loaderLabel = this.translateValues['programs.loader-text'];
        this.savvyAdminService.getSkillGraphData(skillGraphId);
    }

    subscribeToIsErrorOccurred(): void {
        this.savvyAdminService.isErrorOccurred$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isErrorOccured) => {
                if (isErrorOccured) {
                    const programIndex = this.getProgramIndex(this.programId);
                    this.syncedPrograms.data[
                        programIndex
                    ].downloadButtonIconName = downloadButtonIconName.AUTORENEW;
                }
            });
    }

    parseDate(isoDateString: string): string {
        return formatDate(isoDateString, DateFormat.FORMAT, DateFormat.LOCALE);
    }

    openUpdateProgramModal(
        productId: number,
        productName: string,
        activeSkillGraphId: string | number,
        activeSkillGraphName: string,
        isActive: string | boolean
    ): void {
        activeSkillGraphId = activeSkillGraphId ? parseInt(activeSkillGraphId as string) : '';
        this.dialog.open(UpdateProgramModalComponent, {
            disableClose: true,
            data: {
                productId,
                productName,
                activeSkillGraphId,
                activeSkillGraphName,
                isActive,
            },
        });
    }

    openRegisterProgramModal(): void {
        this.dialog.open(RegisterProgramModalComponent, { disableClose: true });
    }

    applyFilter(): void {
        const setFilterValue = this.input.nativeElement.value;

        const newFilteredPrograms = new MatTableDataSource(
            this.defaultSyncedPrograms
        );
        newFilteredPrograms.filter = setFilterValue.toLowerCase();
        newFilteredPrograms.paginator = this.paginator as MatPaginator;
        newFilteredPrograms.sort = this.sort as MatSort;
        this.syncedPrograms = newFilteredPrograms;
    }

    sortSyncedPrograms(syncedPrograms: FilterableSyncedPrograms[]) {
        return syncedPrograms
            .sort(
                (firstProgram, secondProgram) => {
                    const secondProgramCreatedDate = new Date(
                        secondProgram.dateCreated
                    ).valueOf();
                    const firstProgramCreatedDate = new Date(
                        firstProgram.dateCreated
                    ).valueOf();
                    return firstProgramCreatedDate - secondProgramCreatedDate;
                }
            );
    }

    convertDataToString(
        filterableSyncedPrograms: FilterableSyncedPrograms[]
    ): FilterableSyncedPrograms[] {
        filterableSyncedPrograms.forEach((data) => {
            if (data.activeSkillGraphId) {
                data.activeSkillGraphId = data.activeSkillGraphId.toString();
            }
            data.dateCreated = this.parseDate(data.dateCreated);
            data.dateUpdated = this.parseDate(data.dateUpdated);
        });
        return filterableSyncedPrograms;
    }

    getSyncedProgramsFilteredIds(
        filteredData: FilterableSyncedPrograms[]
    ): number[] {
        return filteredData.map((data) => data.id);
    }

    applyFilterOnUpdateAndRegisterProgram(): void {
        this.savvyAdminService.isUpdateProgramCompleted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isUpdated: boolean) => {
                if (isUpdated) {
                    this.applyFilter();
                }
            });
        this.savvyAdminService.isRegisterProgramCompleted$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isRegistered: boolean) => {
                if (isRegistered) {
                    this.applyFilter();
                }
            });
    }

    getProgramIndex(programId: number): number {
        return this.syncedPrograms.data.findIndex(
            (program) => program.id === programId
        );
    }

    ngAfterViewInit() {
        this.syncedPrograms.paginator = this.paginator as MatPaginator;
        this.syncedPrograms.sort = this.sort as MatSort;
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        this.savvyAdminService.setIsErrorOccurred(false);
    }
}
