import { Component, OnInit } from '@angular/core';
import { SavvyAdminService } from './../../core/savvy-admin/savvy-admin.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

  constructor(private readonly service: SavvyAdminService) { }

  ngOnInit() {
    this.service.setIsLoading(false);
  }
}
