import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';
import {
    getSyncedPrograms_getSyncedPrograms as SyncedPrograms,
    getSkillGraphs_getSkillGraphs as SkillGraphs,
    updateProgram as UpdateProgramResponse,
    getSkillGraphCSVs_getSkillGraphCSVs as SkillGraphData,
    getItemPoolCountCSVs as ItemPoolCountData
} from './../../../../schema/graphql-types';
import { SavvyAdminDataService } from './savvy-admin.data.service';

@Injectable({
    providedIn: 'root',
})
export class SavvyAdminService {
    private syncedPrograms: SyncedPrograms[] = [];
    private readonly getSyncedPrograms = new BehaviorSubject<SyncedPrograms[]>(
        this.syncedPrograms
    );
    private readonly getSkillGraphs = new BehaviorSubject<SkillGraphs[]>([]);
    private readonly isUpdateProgramCompleted = new Subject<boolean>();
    private readonly isSkillGraphsLoaded = new BehaviorSubject<boolean>(false);
    private readonly isRegisterProgramCompleted = new Subject<boolean>();
    private readonly isErrorOccurred = new BehaviorSubject<boolean>(false);
    private readonly isSkillGraphIngested = new Subject<boolean>();
    private readonly isLoading = new BehaviorSubject<boolean>(true);
    private readonly skillGraphCSVData = new Subject<SkillGraphData>();
    private readonly itemPoolCountCSVData = new Subject<ItemPoolCountData>();

    isErrorOccurred$ = this.isErrorOccurred.asObservable();
    isUpdateProgramCompleted$ = this.isUpdateProgramCompleted.asObservable();
    isSkillGraphs$ = this.isSkillGraphsLoaded.asObservable();
    getSyncedPrograms$ = this.getSyncedPrograms.asObservable();
    getSkillGraphs$ = this.getSkillGraphs.asObservable();
    getSkillGraphCSVData$ = this.skillGraphCSVData.asObservable();
    getItemPoolCountCSVData$ = this.itemPoolCountCSVData.asObservable();
    isLoading$ = this.isLoading.asObservable();
    isRegisterProgramCompleted$ =
        this.isRegisterProgramCompleted.asObservable();
    isSkillGraphIngested$ = this.isSkillGraphIngested.asObservable();
    errorMessage = '';
    isErrorCodeNaN = false;
    ingestedSkillGraphId: number | null = null;
    ingestedSkillGraphName = '';

    constructor(
        private readonly savvyAdminDataService: SavvyAdminDataService
    ) {}

    loadSyncedPrograms(): void {
        if (!this.syncedPrograms.length) {
            this.isLoading.next(true);
            this.savvyAdminDataService
                .getSyncedPrograms()
                .pipe(first())
                .subscribe((data) => {
                    this.isLoading.next(false);
                    this.syncedPrograms = data.getSyncedPrograms;
                    this.getSyncedPrograms.next(this.syncedPrograms);
                });
        }
    }

    loadSkillGraphs(): void {
        this.savvyAdminDataService
            .getSkillGraphs()
            .pipe(first())
            .subscribe((data) => {
                this.getSkillGraphs.next(data.getSkillGraphs);
                this.isSkillGraphsLoaded.next(true);
            });
    }

    getSkillGraphData(activeSkillGraphId: number): void {
        this.setIsLoading(true);
        this.savvyAdminDataService
            .downloadSkillGraphCSVs({ skillGraphId: activeSkillGraphId })
            .pipe(
                first(),
                catchError((error: any) => this.handleApiError(error))
            )
            .subscribe((data) => {
                this.setIsLoading(false);
                if (data) {
                    this.skillGraphCSVData.next(data.getSkillGraphCSVs);
                } else {
                    this.isErrorOccurred.next(true);
                }
            });
    }

    updateProgram(
        isActive: boolean,
        programId: number,
        productName: string,
        activeSkillGraphId: number,
        previousActiveSkillGraphId: number,
        previousIsActive: boolean,
        previousProductName: string
    ): void {
        this.savvyAdminDataService
            .updateProgram({
                isActive,
                programId,
                productName,
                activeSkillGraphId: activeSkillGraphId || null,
                previousActiveSkillGraphId: previousActiveSkillGraphId || null,
                previousIsActive,
                previousProductName,
            })
            .pipe(first())
            .subscribe((data: UpdateProgramResponse | null | undefined) => {
                this.syncedPrograms = this.syncedPrograms.map((program) =>
                    program.id === programId
                        ? { ...program, ...data?.updateProgram }
                        : program
                );
                this.getSyncedPrograms.next(this.syncedPrograms);
                this.isUpdateProgramCompleted.next(true);
            });
    }

    registerProgram(
        programName: string,
        productName: string,
        grades: number[]
    ): void {
        this.savvyAdminDataService
            .registerProgram({ programName, productName, grades })
            .pipe(first())
            .subscribe((data) => {
                if (data) {
                    this.syncedPrograms = [
                        data.registerProgram,
                        ...this.syncedPrograms,
                    ];
                    this.getSyncedPrograms.next(this.syncedPrograms);
                    this.isRegisterProgramCompleted.next(true);
                } else {
                    this.isErrorOccurred.next(true);
                }
            });
    }

    ingestSkillGraph(
        skillGraph: File,
        skills: File,
        skillRelation: File,
        selectedProgramIds: number[]
    ): void {
        this.savvyAdminDataService
            .ingestSkillGraph({
                skillGraph,
                skills,
                skillRelation,
                programIds: selectedProgramIds,
            })
            .pipe(first())
            .subscribe((data) => {
                if (data) {
                    const { id, name } = data.ingestSkillGraph;
                    this.ingestedSkillGraphId = id;
                    this.ingestedSkillGraphName = name;
                    this.isSkillGraphIngested.next(true);
                }
            });
    }

    setIsErrorOccurred(isErrorOccurred: boolean): void {
        this.isErrorOccurred.next(isErrorOccurred);
    }

    setIsLoading(isLoading: boolean): void {
        this.isLoading.next(isLoading);
    }

    setErrorMessage(errorMessage: string): void {
        this.errorMessage = errorMessage;
    }

    setIsErrorCodeNaN(isErrorCodeNan: boolean): void {
        this.isErrorCodeNaN = isErrorCodeNan;
    }

    setSkillGraphsData(SkillGraphs: SkillGraphs[]): void {
        this.getSkillGraphs.next(SkillGraphs);
    }

    getItemPoolCountCSVData(programId: number, parameter: string): void {
        this.setIsLoading(true);
        this.savvyAdminDataService
            .downloadItemPoolCountCSVs({ programId, parameter })
            .pipe(
                first(),
                catchError((error: any) => this.handleApiError(error))
            )
            .subscribe((data) => {
                this.setIsLoading(false);
                if (data) {
                    this.itemPoolCountCSVData.next(data);
                } else {
                    this.isErrorOccurred.next(true);
                }
            });
    }

    handleApiError(error: any): Observable<never> {
        this.setIsLoading(false);
        this.isErrorOccurred.next(true);
        return throwError(error);
    }
}
