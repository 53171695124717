import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    constructor(private readonly translate: TranslateService) {}

    async get(key: string | Array<string>, interpolateParams?: object): Promise<string | { [key: string]: string }> {
        return new Promise((resolve, reject) => {
            this.translate.get(key, interpolateParams).subscribe((translateValue) => resolve(translateValue), reject);
        });
    }
}
