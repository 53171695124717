// This file should contain ALL keys used by *any* environment
// the values in this file should be that of the DEVELOPMENT environment
// other named environment files (i.e. environment.smoke.ts) should include ONLY overrides to values as necessary
// Common configs point to nightly environment

export const commonConfig = {
    appId: 'savvy-admin-webapp',
    appName: 'savvy-admin-webapp',
    authContextId: '6065',

    production: false,
    authGatewayUrl: 'https://rr-auth-gateway.nightly.savvasrealizedev.com/sapi',
    rbsConfig: {
        scope: 'rbs',
        clientId: 'GTrd4wvsJKWf6JkV0gbxHmgm3lQz20E7',
        grant_type: 'custom_castgc',
    },
    ssoUrl: 'https://nightly-sso.rumba.pk12ls.com/sso',
    ssoLoginQueryParams: {
        k12int: true,
    },
    errorRoute: 'error',
    authCallbackRoute: 'auth-callback',
    backendForFrontend: {
        url: 'https://dev.savvy-admin-bff.savvasrealizedev.com',
        apiPath: '/graphql',
    },
};
