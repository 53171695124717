<div class="modal-title-and-close-icon-wrapper">
    <h1 class="register-program-modal-title" mat-dialog-title>{{ 'register-program.modal.title' | translate }}</h1>
    <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
</div>
<mat-dialog-content class="modal-body" [formGroup]="registerProgramForm">
    <p class="program-name-input-wrapper">
        <span class="modal-description">{{ 'register-program.modal.description.program-name' | translate }}</span>
        <mat-form-field class="program-name-field" appearance="outline">
            <mat-label>{{ 'register-program.modal.program-name-label' | translate }}</mat-label>
            <input formControlName="programName" matInput required/>
        </mat-form-field>
    </p>
    <p class="product-name-input-wrapper">
        <span class="modal-description">{{ 'register-program.modal.description.product-name' | translate }}</span>
        <mat-form-field class="product-name-field" appearance="outline">
            <mat-label>{{ 'register-program.modal.product-name-label' | translate }}</mat-label>
            <input formControlName="productName" matInput required/>
        </mat-form-field>
    </p>
    <p class="grades-selection-wrapper">
        <span class="modal-description">{{ 'register-program.modal.description.grades' | translate }}</span>
        <mat-form-field class="grades-field" appearance="outline">
            <mat-label>{{ 'register-program.modal.grades-label' | translate }}</mat-label>
            <mat-select multiple formControlName="grades">
                <mat-option *ngFor="let grade of gradesList; let index = index" [value]="grade">
                    {{ grade ? grade : 'register-program.modal.grade-kindergarten' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </p>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
    <button class="modal-cancel-button" mat-button mat-dialog-close>
        {{ 'register-program.modal.cancel' | translate }}
    </button>
    <button
        class="modal-save-button"
        mat-raised-button
        [disabled]="!registerProgramForm.valid"
        (click)="registerNewProgram()"
        color="primary"
    >
        <span>{{ 'register-program.modal.save' | translate }}</span>
        <mat-icon *ngIf="isProgramRegistrationInProgress"
            ><mat-spinner class="save-button-spinner" diameter="20" color="warn"> </mat-spinner
        ></mat-icon>
    </button>
</mat-dialog-actions>
