import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LstAuthGuard, LstRouterModule } from '@savvaslearning/lst-auth';
import { SavvyAdminAuthGuard } from './core/savvy-admin/guard/savvy-admin-auth.guard';
import { ErrorComponent } from './pages/error/error.component';
import { HowToComponent } from './pages/how-to/how-to.component';
import { IngestSkillGraphComponent } from './pages/ingest-skill-graph/ingest-skill-graph.component';
import { ProgramsComponent } from './pages/programs/programs.component';
import { CheckPoolCountComponent } from './pages/check-pool-count/check-pool-count.component';
import { DrawerComponent } from './shared/components/drawer/drawer.component';

const authGuards = [LstAuthGuard, SavvyAdminAuthGuard];

const routes: Routes = [
    {
        path:'',
        component: DrawerComponent,
        canActivate: authGuards,
        children: [
            {
                path: '',
                redirectTo: 'how-to',
                pathMatch: 'full',
            },
            {
                path: 'how-to',
                component: HowToComponent,
            },
            {
                path: 'programs',
                component: ProgramsComponent,
            },
            {
                path: 'ingest-skill-graph',
                component: IngestSkillGraphComponent,
            },
            {
                path: 'check-pool-count',
                component: CheckPoolCountComponent
            }

        ]
    },
    {
        path: 'error',
        component: ErrorComponent,
    },
    {
        path: '**',
        redirectTo: 'how-to',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [LstRouterModule.getRoutes(), RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
