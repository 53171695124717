import { Injectable } from '@angular/core';
import { CloseButtonOptions, DialogOptions, dialogController } from '@savvaslearning/cel-components';
import { BehaviorSubject } from 'rxjs';
import { IDialog, ModalEventPayload } from '../../model/dialog.model';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private dialog: IDialog | null = null;
    private readonly closeBtnOptions: CloseButtonOptions = {
        action: () => this.closeDialog(),
    };
    private dialogOptions: DialogOptions = {
        component: null,
        closeButtonOptions: this.closeBtnOptions,
        cssClass: 'savvy-dialog',
    };
    private readonly modalCloseEvent = new BehaviorSubject<ModalEventPayload>({} as ModalEventPayload);
    modalCloseEvent$ = this.modalCloseEvent.asObservable();
    modalEventPayload: ModalEventPayload = {} as ModalEventPayload;

    async openDialog(payload: ModalEventPayload): Promise<void> {
        this.modalEventPayload = payload;
        const { message } = payload;
        this.dialogOptions.component = document.createElement('div');
        const contentHTML = `
        <span>
          ${message}
        </span>
      `;

        this.dialogOptions.component.innerHTML = contentHTML;
        this.dialogOptions.component.classList.add('modal-content');
        this.dialog = await dialogController.create(this.dialogOptions);
        await this.dialog?.present();
    }

    closeDialog(): void {
        this.modalCloseEvent.next(this.modalEventPayload);
        this.dialog && this.dialog.dismiss();
    }
}
