<app-loader [loaderText]="loaderLabel"></app-loader>
<div class="cpc-wrapper">
    <div class="cpc-title-wrapper">
        <h1 class="cpc-title">{{ 'check-pool-count.page-title' | translate }}</h1>
    </div>
    <div class="cpc-options-row-wrapper">
        <div class="cpc-dropdown-group">
            <div class="cpc-select-program">
                <form>
                    <mat-form-field class="mat-form-field" appearance="outline">
                        <mat-label>{{ 'check-pool-count.select-program-label' | translate}}</mat-label>
                        <input type="text"
                                matInput
                                [formControl]="programSelectFormControl"
                                [matAutocomplete]="auto"
                                [matTooltip]="this.selectedProgramToolTip"
                                >
                        <mat-autocomplete #auto="matAutocomplete">
                            <mat-option *ngFor="let program of filteredSyncedPrograms | async " 
                                [value]="program.name"
                                (onSelectionChange)="setSelectedProgram(program, $event)"
                                >
                                {{ program.name }}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </form>
            </div>
            <div class="cpc-select-parameter">
                <mat-form-field class="mat-form-field" appearance="outline">
                    <mat-label>{{ 'check-pool-count.select-parameter-label' | translate}}</mat-label>
                    <mat-select disableOptionCentering panelClass="custom-panel-class" [(value)]="this.selectedParameter">
                        <mat-option *ngFor="let parameter of parameterDropdownOptions" [value]="parameter" (onSelectionChange)="setSelectedParameter(parameter, $event)">
                            {{ parameter.Title | translate }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="cpc-download-btn-wrapper">
            <button
            mat-raised-button
            color="primary"
            class="download-btn"
            [disabled]="disableDownloadBtn"
            (click)="downloadItemCountCSVData(selectedProgramId, selectedParameter.id)"
            >
                <mat-icon>download</mat-icon> {{ 'check-pool-count.download-btn' | translate}}
            </button>
        </div>
    </div>
    <ng-template [ngIf]="displayZeroState">
        <span class="no-data-message">{{ 'check-pool-count.no-data-message' | translate }}</span>
    </ng-template>   
</div>
