import { Component, Input, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from '../../../core/savvy-admin/savvy-admin.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

    isLoading = true;
    @Input() loaderText = '';

    constructor(private readonly savvyAdminService: SavvyAdminService) {
        this.savvyAdminService.isLoading$
            .pipe(takeUntil(this.destroyed$))
            .subscribe((isLoaded) => {
                this.isLoading = isLoaded;
            });
    }

    ngOnDestroy(): void {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
