import gql from 'graphql-tag';

export const ingestSkillGraph = gql`
    mutation ingestSkillGraph($skillGraph: Upload!, $skillRelation: Upload!, $skills: Upload!, $programIds: [Int!]!) {
        ingestSkillGraph(skillGraph: $skillGraph, skillRelation: $skillRelation, skills: $skills, programIds: $programIds) {
            id
            dateCreated
            name
        }
    }
`;
