<ng-template #loading><div class="modal-spinner"><mat-spinner color="primary"></mat-spinner></div></ng-template>
<div  *ngIf="isDataLoaded | async; else loading"> 
<div class="modal-title-and-close-icon-wrapper">
    <h1 class="register-program-modal-title" mat-dialog-title>{{
        'update-program.modal.title' | translate }}</h1>
    <mat-icon mat-dialog-close class="close-dialog">close</mat-icon>
</div>
<mat-dialog-content class="modal-body" [formGroup]="updateProgramForm">
    <p class="program-name-input-wrapper">
        <span class="modal-description">{{ 'update-program.modal.description.product-name' | translate }}</span>
        <mat-form-field class="program-name-field" appearance="outline">
            <mat-label>{{'update-program.modal.product-name-label' | translate}}
            </mat-label>
            <input formControlName="productName" matInput required />
        </mat-form-field>
    </p>
    <p class="active-skill-graph-wrapper">
        <span class="modal-description">{{ 'update-program.modal.description.active-skill-graph' | translate }}</span>
        <mat-form-field class="grades-field" appearance="outline">
            <mat-label>{{ 'update-program.modal.active-skill-graph' | translate }}</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" formControlName="activeSkillGraph">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="autoCompleteDisplay">
                <mat-option *ngFor="let skillGraphsOption of filteredSkillGraphsOptions | async" [value]="skillGraphsOption">
                    {{skillGraphsOption.viewValue}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </p>
    <mat-slide-toggle class="active-toggle" formControlName="isActive"
        >{{ 'update-program.modal.active' |
        translate }}</mat-slide-toggle>
</mat-dialog-content>
<mat-dialog-actions align="end" class="modal-footer">
    <button class="modal-cancel-button" mat-button mat-dialog-close>
        {{ 'update-program.modal.cancel' | translate }}
    </button>
    <button
        class="modal-save-button"
        (click)="updateProgram()"
        [disabled]="!updateProgramForm.valid"
        mat-raised-button
        color="primary">
        <span>{{ 'update-program.modal.save' | translate }}</span>
        <mat-icon *ngIf="isProgramUpdationInProgress"><mat-spinner
                class="save-button-spinner" diameter="20"
                color="warn"> </mat-spinner></mat-icon>
    </button>
</mat-dialog-actions>