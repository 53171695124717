import { TimeOutDuration, TranslationServiceReturnType } from './../../../core/model/savvy-admin.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SavvyAdminService } from '../../../core/savvy-admin/savvy-admin.service';
import { TranslationService } from '../../../core/services/translation/translation.service';
import { ProgramsComponent } from '../../../pages/programs/programs.component';

@Component({
    selector: 'app-register-program-modal',
    templateUrl: './register-program-modal.component.html',
    styleUrls: ['./register-program-modal.component.scss'],
})
export class RegisterProgramModalComponent implements OnInit, OnDestroy {
    private readonly destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    gradesList: number[] = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    isProgramRegistrationInProgress = false;
    translateValues: TranslationServiceReturnType = {};
    registerProgramForm = new FormGroup({
        programName: new FormControl('', Validators.required),
        productName: new FormControl('', Validators.required),
        grades: new FormControl([]),
    });

    constructor(
        private readonly savvyAdminService: SavvyAdminService,
        private readonly dialogRef: MatDialogRef<ProgramsComponent>,
        private readonly _snackBar: MatSnackBar,
        private readonly translationService: TranslationService
    ) { }

    async ngOnInit() {
        this.translateValues = (await this.translationService.get([
            'register-program.snack-bar.message',
            'error.message',
        ])) as TranslationServiceReturnType;
        this.savvyAdminService.isRegisterProgramCompleted$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
            if (status) {
                this.closeTheDialogAndShowSnackBar(this.translateValues['register-program.snack-bar.message']);
            }
        });
        this.savvyAdminService.isErrorOccurred$.pipe(takeUntil(this.destroyed$)).subscribe((status) => {
            if (status) {
                this.savvyAdminService.isErrorCodeNaN
                    ? this.closeTheDialogAndShowSnackBar(this.translateValues['error.message'])
                    : this.closeTheDialogAndShowSnackBar(this.savvyAdminService.errorMessage);
            }
        });
    }

    registerNewProgram(): void {
        this.isProgramRegistrationInProgress = true;
        const { programName, productName, grades } = this.registerProgramForm.value;
        this.savvyAdminService.registerProgram(programName, productName, grades);
    }

    closeTheDialogAndShowSnackBar(snackBarMessage: string): void {
        this.dialogRef.close();
        this.isProgramRegistrationInProgress = false;
        this._snackBar.open(snackBarMessage, '', {
            duration: TimeOutDuration.SIX_SECOND,
        });
        this.savvyAdminService.setIsErrorOccurred(false);
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
