export interface Filenames {
    skills: string;
    skillRelation: string;
}

export interface IFileObject {
    [key: string]: File;
}

export interface IStringObject {
    [key: string]: string;
}

export interface IParsedCsvObject {
    [key: string]: IStringObject[] | null;
}

export enum MultiCheckDropdown {
    dropdownMinTextLength = 100,
    dropdownMaxHeight = '200px',
}

export const ingestSkillGraphTemplatePath = 'assets/template/ingest-skill-graph-template.zip';

export const FILENAME: Filenames = {
    skills: 'skill',
    skillRelation: 'skill-skill',
};

export const skillCsvSavvyHeaders = ['skill_fullpath', 'name', 'description', 'label'];

export const skillCsvRealizeHeaders = [
    'Fullterm',
    'Term',
    'Parent',
    'subject',
    'grade',
    'thumbnail',
    'threshold %',
    'code',
    'description',
    'spanish description',
    'library',
    'topic_mapping',
    'skill_mapping',
    'title',
    'state_code',
    'state description',
    'case item url',
    'case association',
    'mappedSubscriptions',
    'category',
    'mapped-standards',
];

export const skillRelationCsvSavvyHeaders = ['prerequisite_skill_fullpath', 'postrequisite_skill_fullpath', 'strength'];
export const skillRelationCsvRealizeHeaders = [
    'prereq concept id',
    'prereq concept description',
    'postreq concept id',
    'postreq concept description',
    'strength',
    'workflow space',
];

export const skillGraphHeaders = ['name', 'version', 'program_name'];
export enum skillGraphSavvyHeaders {
    NAME = 'name',
    VERSION = 'version',
    PROGRAM_NAME = 'program_name',
}

export const skillGraphName = 'skill-graph';

export enum FileFormControl {
    SKILL_GRAPH = 'skillGraphName'
}

export const DefaultVersionValue = '1';

export const loadingText = 'ingest-skill-graph.dropdown-loading';
