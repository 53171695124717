import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
    IError,
    ConceptPrefixType,
    SkillRelationRealizeHeader,
    SkillRelationSavvyHeader,
    SkillSavvyHeader,
    SkillRealizeHeader,
    ModalMessage,
} from '../../model/convert-realize-files.model';
import { FILENAME, IStringObject } from '../../model/ingest-skill-graph.model';

@Injectable({
    providedIn: 'root',
})
export class ConvertRealizeFilesService {
    private readonly skillRelation = new BehaviorSubject<IStringObject[]>([]);
    private readonly skills = new BehaviorSubject<IStringObject[]>([]);
    private readonly isConvertFileErrorOccurred = new BehaviorSubject<IError>({
        isErrorOccurred: false,
        errorMessage: '',
    });

    skills$ = this.skills.asObservable();
    skillRelation$ = this.skillRelation.asObservable();
    isConvertFileErrorOccurred$ = this.isConvertFileErrorOccurred.asObservable();

    extractHeaders(parsedCsv: IStringObject[]): string[] {
        return Object.keys(parsedCsv[0]);
    }

    convertRealizeSkillOrRelationFiles(parsedCsv: IStringObject[], key: string): void {
        const convertedSavvyFormatData: IStringObject[] = [];
        parsedCsv.forEach((data: IStringObject, index: number) => {
            const convertedSavvyFormatObj =
                key === FILENAME.skills
                    ? this.convertRealizeSkill(data, index)
                    : this.convertRealizeSkillRelation(data);
            convertedSavvyFormatData.push(convertedSavvyFormatObj);
        });
        if (key === FILENAME.skills) {
            this.skills.next(convertedSavvyFormatData);
            return;
        }
        this.skillRelation.next(convertedSavvyFormatData);
    }

    convertRealizeSkill(data: IStringObject, index: number): IStringObject {
        return {
            [SkillSavvyHeader.SKILL_FULL_PATH]: data[SkillRealizeHeader.FULLTERM],
            [SkillSavvyHeader.NAME]: data[SkillRealizeHeader.TERM],
            [SkillSavvyHeader.DESCRIPTION]: data[SkillRealizeHeader.DESCRIPTION],
            [SkillSavvyHeader.LABEL]: SkillRealizeHeader.LABEL + index,
        };
    }

    convertRealizeSkillRelation(data: IStringObject): IStringObject {
        return {
            [SkillRelationSavvyHeader.PRE_REQUISITE_SKILL_FULL_PATH]: this.cleanConceptConceptRelationData(
                data[SkillRelationRealizeHeader.PRE_REQUISITE_CONCEPT_ID]
            ),
            [SkillRelationSavvyHeader.POST_REQUISITE_SKILL_FULL_PATH]: this.cleanConceptConceptRelationData(
                data[SkillRelationRealizeHeader.POST_REQUISITE_CONCEPT_ID]
            ),
            [SkillRelationSavvyHeader.STRENGTH]: data.strength,
        };
    }

    validateCsv(parsedCsv: IStringObject[], realizeCsvHeaders: string[], fileName: string): boolean {
        const csvHeaders = this.extractHeaders(parsedCsv);
        if (!this.checkForCorrectHeaderNames(csvHeaders, realizeCsvHeaders)) {
            const errorOccurredAndErrorMessage: IError = {
                isErrorOccurred: true,
                errorMessage:
                    fileName === FILENAME.skills
                        ? ModalMessage.INCORRECT_SKILL_HEADERS
                        : ModalMessage.INCORRECT_SKILL_RELATION_HEADERS,
                data: {
                    fileName,
                }
            };
            this.setConvertFileErrorOccurred(errorOccurredAndErrorMessage);
            return false;
        }
        return true;
    }

    checkForCorrectHeaderNames(uploadedFileHeaders: string[], realizeFileRequiredHeaders: string[]): boolean {
        return realizeFileRequiredHeaders.every(columnName => uploadedFileHeaders.includes(columnName));
    }

    cleanConceptConceptRelationData(conceptId: string): string {
        if (conceptId.startsWith(ConceptPrefixType.CONCEPT)) {
            return conceptId.slice(8);
        }
        if (conceptId.startsWith(ConceptPrefixType.CREF)) {
            return conceptId.slice(5);
        }
        return conceptId;
    }

    setConvertFileErrorOccurred(errorOccurredAndErrorMessage: IError): void {
        this.isConvertFileErrorOccurred.next(errorOccurredAndErrorMessage);
    }

    resetConvertFileErrorOccurred(): void {
        this.isConvertFileErrorOccurred.next({
            isErrorOccurred: false,
            errorMessage: '',
        });
    }

    resetSkillRelation(): void {
        this.skillRelation.next([]);
    }

    resetSkills(): void {
        this.skills.next([]);
    }
}
