import { IStringObject } from './ingest-skill-graph.model';

export interface IError {
    isErrorOccurred: boolean;
    errorMessage?: string;
    data?: {
        [key: string]: string,
    }
}

export interface IExtractedCSVHeadersAndRowData {
    headers: string[];
    csvRowsData: IStringObject[];
}

export enum SkillRelationRealizeHeader {
    PRE_REQUISITE_CONCEPT_ID = 'prereq concept id',
    POST_REQUISITE_CONCEPT_ID = 'postreq concept id',
}

export enum SkillRelationSavvyHeader {
    PRE_REQUISITE_SKILL_FULL_PATH = 'prerequisite_skill_fullpath',
    POST_REQUISITE_SKILL_FULL_PATH = 'postrequisite_skill_fullpath',
    STRENGTH = 'strength',
}

export enum ModalMessage {
    SUCCESS_MESSAGE_INITIAL = 'ingest-skill-graph.modal-message.success.success-initial',
    SUCCESS_MESSAGE_END = 'ingest-skill-graph.modal-message.success.success-end',
    ERROR_MESSAGE = 'error.message',
    WRONG_FILE = 'error.wrong-file',
    INCORRECT_SKILL_HEADERS = 'ingest-skill-graph.modal-message.errors.incorrect-skill-headers',
    INCORRECT_SKILL_RELATION_HEADERS = 'ingest-skill-graph.modal-message.errors.incorrect-skill-relation-headers',
    FAILED_TO_READ = 'ingest-skill-graph.modal-message.errors.failed-to-read'
}

export enum ConceptPrefixType {
    CONCEPT = 'CONCEPT_',
    CREF = 'cref-',
}

export enum SkillRealizeHeader {
    FULLTERM = 'Fullterm',
    TERM = 'Term',
    DESCRIPTION = 'description',
    LABEL = 'Label',
}

export enum SkillSavvyHeader {
    SKILL_FULL_PATH = 'skill_fullpath',
    NAME = 'name',
    DESCRIPTION = 'description',
    LABEL = 'label',
}
