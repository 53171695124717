<div class="isg-wrapper">
    <div class="isg-title-wrapper">
        <h1 class="isg-title">{{ 'ingest-skill-graph.page-title' | translate }}</h1>
    </div>
    <div class="isg-upload-group" [formGroup]="ingestSkillGraphForm">
        <input
            type="text"
            class="isg-input"
            placeholder="{{ 'ingest-skill-graph.skill-graph-name-label' | translate }}"
            formControlName="skillGraphName"
        />
        <div class="isg-skill-item">
            <div class="isg-skill-download-wrapper">
                <span class="isg-align isg-label">
                    {{ 'ingest-skill-graph.skill' | translate }}
                </span>
                <input
                    #skillFileInput
                    type="file"
                    id="{{ filenames.skills }}"
                    [hidden]="true"
                    accept=".csv"
                    (change)="selectFile($event)"
                />
                <label class="mat-raised-button mat-primary" for="{{ filenames.skills }}">
                    {{ 'ingest-skill-graph.choose-file' | translate }}
                </label>
                <span class="file-name">{{ getFileName(filenames.skills) }}</span>
                <button
                    mat-button
                    class="view-file"
                    *ngIf="parsedCsvs[filenames.skills]"
                    (click)="setValuesIntoTable(filenames.skills)"
                >
                    {{ (isThisFileBeingViewed(filenames.skills)
                            ? 'ingest-skill-graph.hide-file'
                            : 'ingest-skill-graph.view-file'
                        ) | translate }}
                </button>
                <span class="isg-download">
                    <a mat-raised-button color="primary" class="isg-dwnld-templates" [href]="templateUrl">
                        <mat-icon>download</mat-icon> {{ 'ingest-skill-graph.download-skill-template' | translate }}
                    </a>
                </span>
            </div>
            <div class="isg-idescription">{{ 'ingest-skill-graph.description.skill' | translate }}</div>
            <div class="isg-idescription">{{ 'ingest-skill-graph.description.skill-note' | translate }}</div>
        </div>
        <div class="isg-skill-item">
            <span class="isg-label">
                {{ 'ingest-skill-graph.skill-relation' | translate }}
            </span>
            <input
                #skillRelationFileInput
                type="file"
                id="{{ filenames.skillRelation }}"
                [hidden]="true"
                accept=".csv"
                (change)="selectFile($event)"
            />
            <label class="mat-raised-button mat-primary" for="{{ filenames.skillRelation }}">
                {{ 'ingest-skill-graph.choose-file' | translate }}
            </label>
            <span class="file-name">{{ getFileName(filenames.skillRelation) }}</span>
            <button
                mat-button
                class="view-file"
                *ngIf="parsedCsvs[filenames.skillRelation]"
                (click)="setValuesIntoTable(filenames.skillRelation)"
            >
                {{
                    (isThisFileBeingViewed(filenames.skillRelation)
                        ? 'ingest-skill-graph.hide-file'
                        : 'ingest-skill-graph.view-file'
                    ) | translate
                }}
            </button>
            <div class="isg-idescription">{{ 'ingest-skill-graph.description.skill-relation' | translate }}</div>
            <div class="isg-idescription">{{ 'ingest-skill-graph.description.skill-relation-note' | translate }}</div>
        </div>

    </div>
    <div class="isg-upload-wrapper">
        <div class="multicheckDropdown">
            <div class="container multiDropdown">
                <cel-multi-check-dropdown
                    id="celMultiChekDropdown"
                    *ngIf="shouldMountDropdown"
                    [placeholderLabel]="'ingest-skill-graph.program-selection-dropdown-placeholder-label' | translate"
                    [itemsSelectedLabel]="'ingest-skill-graph.program-dropdown-selected-label' | translate"
                    [showTooltip]="true"
                    [maxHeight]="maxHeight"
                    [minTextLength]="minTextLength"
                    [options]="syncedPrograms"
                    [showSearch]="true"
                    [clearOptions]="isProgramSelected"
                    [selectedOptions]="selectedPrograms"
                    [noDataLabel]="noDataLabel"
                    (celDropdownOptionSelected)="onProgramSelectionValueChange($event)">
                </cel-multi-check-dropdown>
            </div>
        </div>

        <button
            mat-raised-button
            color="primary"
            class="isg-upload"
            (click)="upload()"
            [disabled]="!isUploadBtnEnabled && !isUploading"
        >
            <span class="inner-btn-wrapper">
                <mat-icon *ngIf="!isUploading">upload</mat-icon>
                <mat-icon class="spinner-icon" *ngIf="isUploading">
                    <mat-spinner color="accent" diameter="20"></mat-spinner>
                </mat-icon>
                {{ 'ingest-skill-graph.upload' | translate }}
            </span>
        </button>
    </div>
    <div class="mat-elevation-z8 table-wrapper" [hidden]="!isShowCsvTable">
        <table aria-describedby="('ingest-skill-graph.table-data' | translate )" mat-table [dataSource]="tableData">
            <ng-container *ngFor="let col of displayedColumns; let colIndex = index" matColumnDef="{{ col }}">
                <th mat-header-cell *matHeaderCellDef id="columnElement">{{ col }}</th>
                <td mat-cell *matCellDef="let element">{{ element[col] }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="pageSize" showFirstLastButtons> </mat-paginator>
    </div>
</div>
