import gql from 'graphql-tag';

export const getSyncedPrograms = gql`
    query getSyncedPrograms {
        getSyncedPrograms {
            id
            dateCreated
            dateUpdated
            isActive
            uri
            partnerId
            externalId
            realmId
            name
            grades
            product
            activeSkillGraphId
            activeSkillGraphName
        }
    }
`;
