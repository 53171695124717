import gql from 'graphql-tag';

export const getItemPoolCountCSVs = gql`
    query getItemPoolCountCSVs($parameter: String!, $programId: Int!) {
        getItemPoolCountCSVs(parameter: $parameter, programId: $programId) {
            programId
            programName
            contentItemId
            version
            externalId
            poolCount
            numberOfSkillsMapped
            skillCodes
        }
    }
`;
