<div class="how-to-wrapper">
    <h1 class="how-to-wrapper-heading">{{ 'how-to.message.heading' | translate }}</h1>
    <div class="how-to-use-wrapper">
        {{ 'how-to.message.uses.use' | translate }}
        <ul>
            <li class="how-to-uses-list">{{ 'how-to.message.uses.register' | translate }}</li>
            <li class="how-to-uses-list">{{ 'how-to.message.uses.map' | translate }}</li>
            <li class="how-to-uses-list">{{ 'how-to.message.uses.update' | translate }}</li>
            <li class="how-to-uses-list">{{ 'how-to.message.uses.activate-deactivate' | translate }}</li>
        </ul>
    </div>
    <div class="how-to-use-wrapper">
        {{ 'how-to.message.required-items.require' | translate }}
        <ol>
            <li class="how-to-require-list">{{ 'how-to.message.required-items.program-product-name' | translate }}</li>
            <li class="how-to-require-list">{{ 'how-to.message.required-items.skill-graph' | translate }}</li>
            <li class="how-to-require-list" value="2">{{ 'how-to.message.required-items.skills-csv' | translate }}</li>
            <li class="how-to-require-list">{{ 'how-to.message.required-items.skill-relationship' | translate }}</li>
        </ol>
    </div>
    <div class="how-to-use-wrapper">
        {{ 'how-to.message.steps.step' | translate }}
        <ol>
            <li class="how-to-steps-list">{{ 'how-to.message.steps.register' | translate }}</li>
            <li class="how-to-steps-list">{{ 'how-to.message.steps.use-files.new-or-existing' | translate }}
                <ol class="how-to-steps-use-files">
                    <li>{{ 'how-to.message.steps.use-files.existing' | translate }}</li>
                    <li>{{ 'how-to.message.steps.use-files.prepare' | translate }}</li>
                    <li>{{ 'how-to.message.steps.use-files.crucial' | translate }}</li>
                </ol>
            </li>
            <li class="how-to-steps-list">{{ 'how-to.message.steps.upload' | translate }}</li>
            <li class="how-to-steps-list">{{ 'how-to.message.steps.navigate.click-edit' | translate }}
                <ol class="how-to-steps-navigate">
                    <li>{{ 'how-to.message.steps.navigate.select' | translate }}</li>
                </ol>
            </li>
        </ol>
    </div>
</div>
