import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate } from '@angular/router';
import { LstAuthService, LstUserService } from '@savvaslearning/lst-auth';
import { first } from 'rxjs/operators';
import { SnackBar, TimeOutDuration, UserRoles } from '../../model/savvy-admin.model';
import { TranslationService } from '../../services/translation/translation.service';

@Injectable({
    providedIn: 'root',
})
export class SavvyAdminAuthGuard implements CanActivate {
    translateValues: { [key: string]: string } = {};
    constructor(
        private readonly lstAuthService: LstAuthService,
        private readonly lstUserService: LstUserService,
        private readonly _snackBar: MatSnackBar,
        private readonly translationService: TranslationService
    ) {}

    async canActivate(): Promise<boolean> {
        this.translateValues = (await this.translationService.get([SnackBar.AUTH_GUARD_MESSAGE])) as {
            [key: string]: string;
        };
        const userRoles = this.lstUserService.getRoles();
        return this.lstAuthService.isAuthenticated$
            .pipe(first())
            .toPromise()
            .then((isAuthenticated) => {
                if (!isAuthenticated) {
                    return false;
                }
                if (!userRoles.includes(UserRoles.PearsonAdmin)) {
                    this._snackBar.open(this.translateValues[SnackBar.AUTH_GUARD_MESSAGE], '', {
                        duration: TimeOutDuration.THREE_SECOND,
                    });
                    this.lstAuthService.redirectToSSOLogoutPage().pipe(first()).subscribe();
                    return false;
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    }
}
